
.details-logo-div {
    height: 150px;
    text-align: center;
}

.details-logo {
    height: 150px;
    border-radius: 50px;
}

.details-title {
    height: auto;
    text-align: center;
    align-content: center;
    display: grid;
}

.details-content {
    text-align: center;
}

.details-comment {
    text-align: justify;
}

.details-map-div {
    height: 450px;
}

.details-map {
    height: 100%;
    width: 100%;
    border-radius: 25px;
    margin-bottom: 10px;
}

.details-images {
    margin-top: 10px;
    max-width: 100%;
}

.bollard-loading-div {
    text-align: center;
    height: 100px;
}

.spinner-grow {
    height: 100px;
    width: 100px;
}

@media (min-width: 768px) {
    .details-logo-div {
        height: 250px;
        text-align: right;
    }

    .details-logo {
        height: 250px;
        border-radius: 90px;
    }

    .details-title {
        height: 250px;
        text-align: left;
    }
    .details-content {
        height: 300px;
        text-align: right;
    }
}