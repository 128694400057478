
.img-loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-preview-div {
  align-items: flex-start;
}

.img-preview {
  max-width: 100%;
}

.card {
  height: 100%;
}

.card-text {
  font-size: 20px;
}

.bollard-card {
  max-width: 262px;
  padding: 5px;
}

@media (min-width: 768px) {
  .bollard-card {
    width: 262px;
    max-width: 262px; 
  }
}
