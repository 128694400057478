
#home-page-title-div {
    max-width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 30px;
    overflow: hidden;
}

.map-link-div {
    margin-bottom: 30px;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
}

.map-link-image {
    width: 100%;
}

.title-text {
    font-family: 'Roboto Serif';
    font-size: 70px;
}